<template>
	<v-app>
		<div class="shell-main">
			<div class="shell-top">
				<div class="top-content">
					<a href="https://www.afrsbc.ca/" target="_blank" class="logo">AFRSBC</a>
					<nav>
						<ul class="shell-navigation">
							<li><a href="https://www.afrsbc.ca/" target="_blank" class="nav-link">Back to AFRSBC Site</a></li>
						</ul>
					</nav>
				</div>
			</div>

			<v-content>
				<div class="shell-container">
					<router-view />
				</div>
			</v-content>

			<div class="shell-copyright">
				<div class="copyright-content">
					{{ copyrightYear }} &copy; Abbotsford Fire Rescue Service
				</div>
			</div>
		</div>
	</v-app>
</template>

<script>
import axios from 'axios';

import alerts from 'permits/shared/alerts';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').getAttribute('content');

export default {
	data()
	{
		return {
			copyrightYear: (new Date()).getFullYear(),
			processing: false,
			username: '',
			password: '',
			error: null
		}
	},

	methods: {
		async submit(event)
		{
			var data = { 
				email: this.username,
				password: this.password,
			};

			event.preventDefault();
			event.stopPropagation();

			try
			{
				var result;

				this.processing = true;
				this.error = null;
				
				result = await axios.post('/authenticate', data);
				
				if (result.data.success)
					document.location = '/users';
				else
					alerts.show('Invalid email address or password', 'Login Failed', 'error');
			}
			catch(err)
			{
				this.error = err.response ? err.response.data.error : err.message;
			}
			finally
			{
				this.processing = false;
			}

			return false;

			/*
			this.processing = true;
			this.error = null;
			axios.post('/auth/authenticate', data).then(resp => {
				document.location = '/';
			}).catch(err => {
				this.error = err.response ? err.response.data.error : err.message;
			}).then(() => {
				this.processing = false;
			});
			*/
		},
	}
}

</script>