<template>
	<v-container>
		<v-form ref="form" @submit.prevent="submit">
			<v-card class="elevation-12 mb-2">
				<v-toolbar dark color="primary" elevation="0">
					<v-toolbar-title>Password Recovery</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<v-text-field 
						autofocus
						prepend-icon="email" 
						type="text" 
						label="Email Address"
						:rules="rules.email"
						v-model="email"  
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" type="submit" :loading="processing" :disabled="processing">Send Reset Instructions</v-btn>

					<v-spacer />

					<v-btn text type="button" to="/login">Back to Login</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-container>
</template>

<script>
import axios from 'axios';

import alerts from 'permits/shared/alerts';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').getAttribute('content');

export default {
	data()
	{
		return {
			processing: false,
			email: '',

			rules: {
				email: [ v => !!v || 'Please enter your email address' ],
			}
		}
	},

	methods: 
	{
		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.processing = true;

			try
			{
				var params = { email: this.email };
				
				await axios.post('/password_recovery/send_reset_email', params);
				await alerts.show('You will receive password reset instructions shortly', 'Thank You!', 'success');

				this.email = '';
			}
			finally
			{
				this.processing = false;
			}
		}
	}
}
</script>