<template>
		<v-container fluid grid-list-xl>
			<v-layout align-center>
				<!-- EXISTING USER LOGIN FORM -->
				<v-flex md6>
					<v-form @submit="submit">
						<v-card class="elevation-12 mb-2">
							<v-toolbar dark color="primary" elevation="0">
								<v-toolbar-title>Existing User Login</v-toolbar-title>
							</v-toolbar>
							<v-card-text>
								<v-alert :value="error != null" type="error">{{ error }}</v-alert>
								<v-text-field prepend-icon="mdi-account-circle" label="Email Address" type="text" v-model="username" autofocus></v-text-field>
								<v-text-field prepend-icon="mdi-lock" label="Password" type="password" v-model="password"></v-text-field>
							</v-card-text>
							<v-card-actions>
								<v-btn color="primary" type="submit" :loading="processing" :disabled="processing">Login</v-btn>
								<v-spacer />

								<v-btn text type="button" to="/forgot_password">Forgot Password</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
				</v-flex>

				<!-- NEW CUSTOMERS -->
				<v-flex md6>
					<div class="text-h4 text-center mb-4">New Users</div>

					<div class="text-body-1 text-center mb-4">
						If you do not have an account already use the button below to proceed to the permit registration process
					</div>

					<div class="text-center">
						<v-btn large color="primary" href="/users/ordering/start">Order Permits</v-btn>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
</template>

<script>
import axios from 'axios';

import alerts from 'permits/shared/alerts';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').getAttribute('content');

export default {
	data()
	{
		return {
			copyrightYear: (new Date()).getFullYear(),
			processing: false,
			username: '',
			password: '',
			error: null
		}
	},

	methods: {
		async submit(event)
		{
			var data = { 
				email: this.username,
				password: this.password,
			};

			event.preventDefault();
			event.stopPropagation();

			try
			{
				var result;

				this.processing = true;
				this.error = null;
				
				result = await axios.post('/authenticate', data);
				
				if (result.data.success)
					document.location = '/users';
				else
					alerts.show('Invalid email address or password', 'Login Failed', 'error');
			}
			catch(err)
			{
				this.error = err.response ? err.response.data.error : err.message;
			}
			finally
			{
				this.processing = false;
			}

			return false;

			/*
			this.processing = true;
			this.error = null;
			axios.post('/auth/authenticate', data).then(resp => {
				document.location = '/';
			}).catch(err => {
				this.error = err.response ? err.response.data.error : err.message;
			}).then(() => {
				this.processing = false;
			});
			*/
		},
	}
}

</script>