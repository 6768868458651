
import 'vuetify/dist/vuetify.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';

// import shell component
import LoginScreen from 'permits/frontend/shell/comp/LoginScreen.vue';
import LoginForm from 'permits/frontend/shell/comp/LoginForm.vue';
import ForgotPassword from 'permits/frontend/shell/comp/ForgotPassword.vue';
import ResetPassword from 'permits/frontend/shell/comp/ResetPassword.vue';

Vue.use(VueRouter);

// configure vuetify
const themeColors = {
	primary: '#d9251c',
	accent: '#d7736e',
	secondary: "#b20000",
};

Vue.use(Vuetify);
const vuetify = new Vuetify({
	theme: {
		themes: {
			dark: themeColors,
			light: themeColors,
		},
		dark: false,
	}
});

const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			name: 'login-form',
			path: '/login',
			component: LoginForm,
		},
		{
			name: 'forgot-password',
			path: '/forgot_password',
			component: ForgotPassword,
		},
		{
			name: 'reset-password',
			path: '/reset_password',
			component: ResetPassword,
		}
	]
});

// initialize main vue instance
const app = new Vue({
	el: '#app-root',
	vuetify,
	router,

	render(h) {
		return h(LoginScreen);
	}
});