<template>
	<v-container>
		<v-card v-if="!valid">
			<v-card-text>
				Link is invalid or has expired
			</v-card-text>
		</v-card>

		<v-form v-else ref="form" @submit.prevent="submit">
			<v-card class="elevation-12 mb-2">
				<v-toolbar dark color="primary" elevation="0">
					<v-toolbar-title>Reset Password</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<v-text-field 
						type="password" 
						label="New Password"
						:rules="rules.password"
						v-model="form.password"
					/>

					<v-text-field 
						type="password" 
						label="Re-enter Password"
						:rules="rules.password_conf"
						v-model="form.password_confirmation"
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" type="submit" :loading="processing" :disabled="processing">Reset Passwords</v-btn>

					<v-spacer />

					<v-btn text type="button" to="/login">Back to Login</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-container>
</template>

<script>
import axios from 'axios';

import alerts from 'permits/shared/alerts';
import validate from 'permits/shared/validators';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').getAttribute('content');

export default {
	data()
	{
		return {
			valid: true,
			processing: false,
			form: {
				token: '',
				password: '',
				password_confirmation: '',
			},

			rules: {
				password: [ 
					v => !!v || 'Please enter a password',
					v => validate.password(v) || 'Passwords must be six characters or longer',
				],

				password_conf: [ v => v == this.form.password || 'Passwords do not match' ],
			}
		}
	},

	methods: 
	{
		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.processing = true;

			try
			{
				await axios.post('/password_recovery/submit_reset_password', this.form);
				await alerts.show('Your password has been reset successfully. You will now be redirected to the login screen.', 'Success', 'success');

				document.location = '/login';
			}
			finally
			{
				this.processing = false;
			}
		}
	},

	created()
	{
		if (window._initData && window._initData.tokenValid)
		{
			this.valid = true;
			this.$set(this.form, 'token', window._initData.tokenValue);
		}
		else
		{
			this.valid = false;
		}
	}
}
</script>